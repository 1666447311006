<template>
  <div id="app">
    <NavBar v-if="$route.name != 'login'" />
    <router-view />
  </div>
</template>

<script>
import NavBar from "./components/NavBar";

export default {
  name: "App",
  components: {
    NavBar,
  },
};
</script>

<style>
.btn-primary {
  color: white !important;
  background-color: #019bb3 !important;
  border-color: #019bb3 !important;
  box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%) !important;
}
.btn-outline-primary {
  color: #019bb3 !important;
  border-color: #019bb3 !important;
  box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%) !important;
}
.btn-outline-primary:hover {
  color: #fff !important;
  background-color: #019bb3 !important;
  border-color: #5e72e4;
}
.dropdown-item.active,
.dropdown-item:active {
  background-color: #019bb3 !important;
}
/* Multiselect Highlight */
span.multiselect__option--highlight {
  background: #E1EBCA;
  outline: none;
  color: #000;
}
span.multiselect__option--highlight::after {
  background: #E1EBCA;
  outline: none;
  color: #000;
}
</style>

