<template>
    <div class="">
        <Loader v-if="loading" />

        <b-modal @hide="hideModal()" content-class="highlight-modal-content"
            dialog-class="highlight-modal-dialogue" hide-header hide-footer centered id="modal-manage-efdb">
            <h1 class="highlight-modal-title">Add a new EFDB</h1>
            <div class="padding-40">
                <b-form b-form @submit.prevent="uploadEFDB()" @reset.prevent="clearData()">
                    <div class="mb-20">
                        <b-form-input autofocus v-model="efdb_name" placeholder="Enter a EFDB Name" required=True
                            :state="efdb_name === '' ? null : checkNameforAsciiCharacters()"
                            aria-describedby="efdb-name-help efdb-name-feedback" />
                        <b-form-invalid-feedback id="efdb-name-feedback">
                            Min. 5 and Max. 50 characters. Alphanumberics, special symbols are allowed, no leading/trailing spaces.
                        </b-form-invalid-feedback>
                        <b-form-valid-feedback id="efdb-name-help">
                            Looks good!
                        </b-form-valid-feedback>
                    </div>

                    <b-form-file
                        id="importEFDB" @change="importEFDB($event)"
                        accept=".csv"  required=True
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here..."
                    />

                    <div class="efdb-modal-footer">
                        <b-button type="submit" variant="primary">Submit</b-button>
                        <b-button type="reset">Reset</b-button>
                    </div>
                </b-form>
            </div>
        </b-modal>

    </div>
</template>

<script>
import Swal from "sweetalert2";

import Loader from "./Loader";


export default {
    components: {
        Loader
    },
    data() {
        return {
            loading: false,
            efdb_name: "",
            efdb_file: ""
        };
    },
    name: "ManageEFDBModal",
    methods: {
        hideModal() {
            this.$root.$emit("bv::hide::modal", "modal-manage-efdb");
        },
        checkNameforAsciiCharacters() {
            if (this.efdb_name.charCodeAt(0) === 32) return false

            if (this.efdb_name.trim().length < 5) return false

            let flag = true;
            for (let i = 0; i < this.efdb_name.length; i++) {
                if (this.efdb_name.charCodeAt(i) < 32 || this.efdb_name.charCodeAt(i) > 125) {
                    flag = false;
                    break;
                }
            }
            return flag;
        },
        importEFDB(event) {
            this.efdb_file = event.target.files[0];
        },
        uploadEFDB() {
            this.loading = true;

            let formData = new FormData();
            formData.append("efdb_name", this.efdb_name);
            formData.append("file", this.efdb_file);

            this.axios
                .post(`/uploadEFDB`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                })
                .then((response) => {
                    console.log(response);
                    this.loading = false;

                    this.$toasted.success("EFDB has been successfully created !!");
                })
                .catch(error => {
                    this.loading = false;
                    console.log("Import EFDB err", error);
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: error.message,
                    });
                })
                .finally(() => {
                    this.hideModal();
                    this.clearData();
                });
        },
        clearData() {
            this.efdb_name = "";
            this.efdb_file = "";
        }
    }
}

</script>

<style lang="scss">
.mr-60 {
    margin-right: 60px !important;
}

.mb-40 {
    margin-bottom: 40px;
}

.mb-20 {
    margin-bottom: 20px;
}

.padding-40 {
    padding: 0 40px;
}

.highlight-modal-title {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 42px;
    text-align: center;
    color: #53607E;
    margin-bottom: 42px;
}

.highlight-modal-content {
    border-radius: 10px !important;
}

.highlight-modal-dialogue {
    max-width: 700px;
}
.custom-file-label:hover {
    cursor: pointer !important;
}

.efdb-modal-footer {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;

    margin-top: 40px;

    .btn {
        background: #FFFFFF;
        border: 1px solid #000000;
        border-radius: 10px;
        padding: 12px 24px;
        width: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

li.username-label {
    // border: 1px solid #707070;
    width: auto;
    height: auto;
    // border-radius: 50%;
    margin: 10px;
    padding: 0px 10px;
    color: #676161;
    // font-size: 2.4em !important;
    // font-weight: 400 !important;
    text-align: center;
    cursor: pointer;
    list-style: none;
}
// li.username-label:hover {
//     background-color: #f8f8f8;
// }
.collaborators-list {
    max-height: 250px;
    overflow-y: scroll;
}

</style>
