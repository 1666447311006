<template>
    <div class="loader">
        <b-spinner
            :indeterminate="true"
            shape="line"
            size="200"
            fillDuration="1"
        />
    </div>
</template>

<script>
export default {
  name: 'Loader',
}
// #019BB3
</script>
<style scoped lang="scss">
.loader{
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 5400;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
<style lang="scss">
.vue-progress-path .progress{
    stroke: #019BB3 !important;
}
</style>