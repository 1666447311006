<template>
    <div class="">
        <Loader v-if="loading" />

        <b-modal @show="getUsers()" @hide="hideModal()" content-class="highlight-modal-content"
            dialog-class="highlight-modal-dialogue" hide-header hide-footer centered id="modal-manage-collaborators">
            <h1 class="highlight-modal-title">Manage Collaborators</h1>
            <div class="padding-40">
                <div class="">
                    <div class="collaborators-list">
                        <div v-for="user of users" :key="user.name">
                            <template v-if="user.owner">
                                <li :class="'username-label blue'">
                                    {{ user.name }} <i>(Project owner)</i>
                                </li>
                            </template>
                            <template v-else-if="user.email == $store.getters.email">
                                <li :class="'username-label blue'">
                                    {{ user.name }} <i>(Current user)</i>
                                </li>
                            </template>
                            <template v-else>
                                <li :class="user.selected ? 'username-label blue' : 'username-label'"
                                    @click="user.selected = !user.selected">
                                    {{ user.name }}
                                </li>
                            </template>
                        </div>
                    </div>
                    <div class="w-100 text-center">
                        <b-button pill class="m-2" variant="outline-primary" size="sm"
                            @click="selectAllUsers()" type="button">
                            Select All Users
                        </b-button>
                        <b-button pill class="m-2" variant="outline-primary" size="sm"
                            @click="deselectAllUsers()" type="button">
                            Deselect All Users
                        </b-button>
                    </div>
                </div>
            </div>
            <div class="highlight-modal-footer">
                <b-button @click="hideModal()" class="btn mr-60">Back</b-button>
                <b-button @click="updateCollaborators()" class="btn">Update Collaborators</b-button>
            </div>
        </b-modal>

    </div>
</template>

<script>
import Loader from "../components/Loader";
// import axios from "../utility/axios";
import Swal from "sweetalert2";

export default {
    components: { Loader },
    data() {
        return {
            loading: false,
            users: []
        };
    },
    name: "ProjectHighlightsModal",
    methods: {
        hideModal() {
            this.$root.$emit("bv::hide::modal", "modal-manage-collaborators");
        },
        async updateCollaborators() {
            console.log(this.users);
            this.loading = true;
            this.axios
                .post(`/collaborators?project_id=${this.$store.getters.highlightsProjectId}`,
                    {
                        "users": this.users
                    }
                )
                .then((res) => {
                    let result = res.data.result;
                    this.$toasted.success("Collaborators updated !!");
                    console.log("Results: ", result);
                })
                .catch((error) => {
                    this.$toasted.error("Adding/Removing Collaborators Failed. Please try again !!");
                    console.log("error: ", error);
                })
                .finally(() => {
                    this.loading = false;
                    this.hideModal();
                });
        },
        getUsers() {
            this.loading = true;
            this.axios
                .get(`/collaborators?project_id=${this.$store.getters.highlightsProjectId}`)
                .then((res) => {
                    this.users = res.data.users;
                })
                .catch((error) => {
                    // console.log(error.response.data.error);
                    Swal.fire({
                        icon: "error",
                        title: "New Error",
                        text: error.message
                        // text: error.response.data.err
                    });
                    this.hideModal();
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        selectAllUsers() {
            for(let idx in this.users) {
                this.users[idx].selected = true;
            }
        },
        deselectAllUsers() {
            for(let idx in this.users) {
                if(this.users[idx].owner || this.$store.getters.email == this.users[idx].email) {
                    // pass
                } else {
                    this.users[idx].selected = false;
                }
            }
        },
    }
}

</script>

<style lang="scss">
.mr-60 {
    margin-right: 60px !important;
}

.mb-40 {
    margin-bottom: 40px;
}

.mb-30 {
    margin-bottom: 30px;
}

.padding-40 {
    padding: 0 40px;
}

.highlight-modal-title {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 42px;
    text-align: center;
    color: #53607E;
    margin-bottom: 42px;
}

.highlight-modal-content {
    border-radius: 10px !important;
}

.highlight-modal-dialogue {
    max-width: 700px;
}

.highlight-modal-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;

    .btn {
        background: #FFFFFF;
        border: 1px solid #000000;
        border-radius: 10px;
        padding: 12px 24px;
        width: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

li.username-label {
    // border: 1px solid #707070;
    width: auto;
    height: auto;
    // border-radius: 50%;
    margin: 10px;
    padding: 0px 10px;
    color: #676161;
    // font-size: 2.4em !important;
    // font-weight: 400 !important;
    text-align: center;
    cursor: pointer;
    list-style: none;
}
// li.username-label:hover {
//     background-color: #f8f8f8;
// }
.collaborators-list {
    max-height: 250px;
    overflow-y: scroll;
}

</style>
